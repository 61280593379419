import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

// import moment from 'moment';
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { animated, useSpring, useChain, config } from "react-spring";

// import {useSpringRef} from 'react-spring/renderprops';

import "./Frontpage.style.scss";

// import Background from '../..Components/Background/Background.component.jsx';

import Background from "../../Components/Background/Background.component";

const Frontpage = ({ userSnapshot, timeStamp }) => {
  const [fadeInTitle, setFadeInTitle] = useState(false);
  const [fadeInBackground, setFadeInBackground] = useState(false);
  const [showAbout, toggleshowAbout] = useState(false);

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 1000px)",
  });

  //   const backgroundSpringRef = useRef()

  //   const {backgroundSpringStyle} = useSpring({
  //     // ref: backgroundSpringRef,
  //     config: {duration:500},
  //     from: { opacity:0},
  //     to: {opacity:1 },
  //   })

  //   const titleSpringRef = useRef();

  const backgroundSpringStyle = useSpring({
    filter: fadeInBackground ? "blur(0px)" : "blur(20px)",
    opacity: fadeInBackground ? 1 : 0,
    config: { duration: 300 },
  });

  const titleSpringStyle = useSpring({
    opacity: fadeInTitle ? 1 : 0,
    config: { duration: 300 },
  });

  useEffect(() => {
    setFadeInBackground(true);
    setTimeout(function () {
      setFadeInTitle(true);
    }, 300);
  }, []);

  return (
    <div id="home">
      {isTabletOrMobileDevice ? (
        <animated.div style={titleSpringStyle} className="button small-text">
          <Link to={"/Photobooth"}>Submit background image</Link>
        </animated.div>
      ) : (
        <animated.div style={titleSpringStyle} className="button small-text">
          <Link to={"/Photobooth"}>Submit background image</Link>
        </animated.div>
      )}

      <animated.div style={titleSpringStyle} className="intro-wrapper">
        <div className="intro-text">
          Jonas Ersland is a Berlin-based artist and director creating moving
          images.
          <br />
          <br />
          His work is centred around combining technology and videography to
          capture scenes, moments and images in ways that exaggerates reality.
          Next to this autonomous art practice he collaborates with clients in
          the fields of art and fashion.
        </div>

        <div className="intro-column small-text">
          <em>Selected clients</em>
          <br />
          <br />
          Balenciaga
          <br />
          Anonymous Club
          <br />
          Shayne Oliver
          <br />
          PUMA
          <br />
          MSCHF
          <br />
          Post Archive Faction
          <br />
          Heliot Emil
          <br />
          Highsnobiety
          <br />
          GOAT
          <br />
          Montblanc
        </div>
        <div className="intro-column small-text">
          <em>Contact</em>
          <br />
          <br />
          <a href="https://www.instagram.com/jonasersland/">Instagram</a>
          <br />
          <a href="mailto:jonasersland@gmail.com">Email</a>
          <br />
          <br />
          {/* {isTabletOrMobileDevice ? (
            <Link to={"/Photobooth"}>Submit background image</Link>
          ) : (
            ""
          )} */}
        </div>
      </animated.div>

      <div className="backgroundWrapper">
        <div className="background-cover"></div>
        <Background userSnapshot={userSnapshot} />
      </div>
    </div>
  );
};

export default Frontpage;
